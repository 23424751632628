
// export const GOOGLE_OAUTH_REDIRECT_URI = "http://localhost:3000/auth/google/callback" // local
export const REACT_APP_GOOGLE_CLIENT_ID = "570518782974-jdus84o00bp52n37raodfb01gv79usad.apps.googleusercontent.com" // deploy
export const GOOGLE_OAUTH_REDIRECT_URI = "https://chorokweb.shop/auth/google/callback" // deploy

export const GOOGLE_AUTH_URL = "https://accounts.google.com/o/oauth2/auth?client_id=" + 
                                // process.env.REACT_APP_GOOGLE_CLIENT_ID + 
                                REACT_APP_GOOGLE_CLIENT_ID + 
                                "&redirect_uri=" + GOOGLE_OAUTH_REDIRECT_URI +
                                "&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile" + 
                                "&response_type=token" + 
                                "&include_granted_scopes=true"